import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../components/Projects/ProjectCard";
import Particle from "../components/Particle";
import pg from "../assets/projects/pg.png";
import project from "../assets/projects/project.jpeg";
import lift from "../assets/projects/lift.png";
import krypto from "../assets/projects/krypto.png";
import kickstart from "../assets/projects/kickstart.png";

const Projects = () => {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Recent Top <strong className="yellow">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pg}
              isBlog={false}
              title="Axle LMS Website"
              description="Developed a robust LMS featuring interactive course authoring, streamlined user management with bulk creation and access control, and a gamified system awarding badges for course completion. Centralized location and room management provided real-time visibility and optimized resource allocation. Built with a scalable back-end and a responsive front-end for a seamless user experience."
              demoLink="https://xlmssg.novaclearning.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={project}
              isBlog={false}
              title="Time Sheet Panel"
              description="Implemented automated timesheet notifications to improve efficiency and accountability, and streamlined complaint handling with an easy-to-use online form for quicker issue resolution. Enhanced loan acquisition with an Instant Loan process for Chola, and simplified food ordering with a scheduling tool. Fostered community engagement by introducing a birthday recognition feature, promoting a positive work environment."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kickstart}
              isBlog={false}
              title="Learning Management"
              description="Enhanced user experience with a redesigned interface, elevated brand awareness through a captivating website, and successfully integrated Metamask for decentralized payments. Streamlined sales reporting with an automated system providing real-time insights for data-driven decisions."
              demoLink="https://klass.novaclearning.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kickstart}
              isBlog={false}
              title="Whisky Exchange"
              description="Elevated artist visibility with detailed pages featuring captivating bios, streamlined NFT browsing with a user-friendly product list, and ensured secure NFT acquisition with a seamless checkout page. Enhanced personalization with dedicated user profiles and order pages for convenient management of NFT collections."
              demoLink="https://www.thewhiskyexchange.com/"
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={project}
              isBlog={false}
              title="Chola Home Loan Mobile App"
              description="Simplified loan assessment with a Loan Eligibility tool for quick determination of eligibility, and enhanced financial planning with an EMI Calculator for estimating monthly repayments. Improved loan management with a Track Your Loan feature for easy access to loan details, repayment schedules, and account information."
            />
          </Col>
         
        </Row>
      </Container>
    </Container>
  )
}

export default Projects